import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g id="Logo1" transform="translate(2.000000, 3.000000)">
      <polygon
        id="Shape"
        stroke="#64FFDA"
        strokeWidth="4"
        fill="#0A192F"
        fillRule="nonzero"
        points="39 0 0 22 0 67 39 90 78 68 78 23"></polygon>
      <text
        id="O"
        fontFamily="Calibre-Regular, Calibre"
        fontSize="66"
        fontWeight="normal"
        fill="#64FFDA">
        <tspan x="18" y="65">
          O
        </tspan>
      </text>
    </g>
  </svg>
);

export default IconLogo;
